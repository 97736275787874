import React from 'react';
import { Link } from 'gatsby';

export default ({theme}) =>
{

    const out =
    <Link to={theme == 'N' ? '/?Jour' : '/?Nuit'} className="btn-switch">
    <svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 46">
        <g>
            <path className="btn-switch__border" d="M60.64,1.14a21.86,21.86,0,0,1,0,43.72H24.36a21.86,21.86,0,0,1,0-43.72Z" fill="none" stroke="#1d1d1b" stroke-miterlimit="10" stroke-width="1.58"/>
            <path className="btn-switch__bg" d="M60.64,6.62a16.38,16.38,0,0,1,0,32.76H24.36a16.38,16.38,0,0,1,0-32.76Z" fill="#1d1d1b"/>
        </g>
        <circle className="btn-switch__bull" cx="59.99" cy="22.91" r="12.22" fill="#fff"/>
        <path style={{transform:'translateX(2px)'}} d="M28,22.8a5.43,5.43,0,0,1-5.44,5.43,5.3,5.3,0,0,1-2.35-.54,5.43,5.43,0,0,0,0-9.8A5.43,5.43,0,0,1,28,22.8Z" fill="#fff"/>
        <g className="btn-switch__sun" style={{transform:'translateX(0.25px)'}}>
            <circle cx="59.99" cy="22.79" r="2.45" fill="#1d1d1b"/>
            <g>
            <path d="M60.53,18.9a.54.54,0,1,1-1.07,0V18a.54.54,0,1,1,1.07,0Z" fill="#1d1d1b"/>
            <path d="M60.53,27.82a.54.54,0,0,1-1.07,0v-.91a.54.54,0,0,1,1.07,0Z" fill="#1d1d1b"/>
            </g>
            <g>
            <path d="M63.24,20.5a.55.55,0,0,1-.76,0,.54.54,0,0,1,0-.76l.65-.63a.53.53,0,0,1,.75,0,.52.52,0,0,1,0,.75Z" fill="#1d1d1b"/>
            <path d="M56.85,26.72a.55.55,0,0,1-.76,0,.54.54,0,0,1,0-.76l.65-.63a.53.53,0,0,1,.74.77Z" fill="#1d1d1b"/>
            </g>
            <g>
            <path d="M64,23.52a.54.54,0,0,1-.53-.54.54.54,0,0,1,.55-.53l.9,0a.54.54,0,0,1,.53.55.54.54,0,0,1-.55.52Z" fill="#1d1d1b"/>
            <path d="M55.07,23.34a.53.53,0,0,1-.52-.54.52.52,0,0,1,.54-.52H56a.54.54,0,0,1,0,1.07Z" fill="#1d1d1b"/>
            </g>
            <g>
            <path d="M62.38,26.17a.54.54,0,0,1,0-.76.53.53,0,0,1,.75,0l.63.65a.53.53,0,0,1-.77.74Z" fill="#1d1d1b"/>
            <path d="M56.2,19.74a.55.55,0,0,1,0-.76A.53.53,0,0,1,57,19l.63.65a.53.53,0,0,1,0,.75.54.54,0,0,1-.76,0Z" fill="#1d1d1b"/>
            </g>
        </g>
    </svg>
</Link>   

    return out;

}