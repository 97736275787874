import React , {useState} from 'react'
import TileList from './TileList'
import SwitchButton from './SwitchButton'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'

const Home = ({theme,posts,og}) =>
{

    const [isOpen,setIsOpen] = useState(false);

    if (typeof window !== 'undefined')
    {

        theme = window.location.search == '?Nuit' ? 'N' : 'D';
        document.body.parentElement?.setAttribute('theme',theme);

    }

    else
    {

        theme = 'D'

    }

    function onClickShareButton(e)
    {

        e.preventDefault();
        setIsOpen(!isOpen);
        return false;
    }

    const out =
        <div className="home">
            <Helmet>
                <link rel="canonical" href="https://smilealldayandnight.fr/"/>
                <meta property="og:url" content={og.url} />
                <meta property="og:type" content={og.type} />
                <meta property="og:title" content={og.title} />
                <meta property="og:description" content={og.description} />
                <meta property="og:image" content={og.image[0]} />
                <meta property="og:image:width" content="1600" />
                <meta property="og:image:height" content="900" />
                <meta property="og:image" content={og.image[1]} />
                <meta property="og:image:width" content="1600" />
                <meta property="og:image:height" content="900" />
                <meta name="description" content={og.description} />
            </Helmet>
            <div className="home__layout-wrapper">
                <div className="home__layout">
                    <div className="home__cell home__cell--aside">
                        <div className="home__cell-inner">
                            <div className="home__content">
                                <Link className="Home-Mentions Home-Mentions--desktop" to="/mentions_legales" style={{position:'fixed',top:50,left:50,textDecoration:'underline',fontSize:11,fontWeight:600}}>Mentions Légales</Link>
                                <div className="home_logos">
                                    <div className="home__logo-wrapper home__logo-wrapper--sephora">
                                        <a data-trackable="1" title="sephora" target="_blank" rel="noopener" href="https://www.sephora.fr/" className="home__logo-sephora"/>
                                    </div>
                                    <div className="home__logo-amp">&amp;</div>
                                    <div className="home__logo-wrapper home__logo-wrapper--laneige">
                                        <a data-trackable="1" title="laneige" target="_blank" rel="noopener" href="https://www.laneige.com/int/en/" className="home__logo-laneige"/>
                                    </div>
                                </div>
                                <p className="home__text home__text-present">Présentent</p>
                                <div className="home__logo-op-wrapper">
                                    <div className="home__logo-op"></div>
                                </div>
                                <p className="home__text home__text-msg">GARDEZ LE SOURIRE JOUR ET NUIT</p>
                                <p className="home__text home__text-switch">{theme == 'N' ? 'Allumer' : 'éteindre'} la lumière</p>
                                <p className="home__button-wrapper">
                                    <SwitchButton theme={theme} />
                                </p>
                                <p className="home__text home__text-mode">Mode {theme == 'N' ? 'Nuit' : 'Jour'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="home__cell home__cell--tile-list">
                        <div className="home__cell-inner">
                            <TileList posts={posts} theme={theme}/>
                        </div>
                    </div>
                </div>
                <div className="home__deco-wrapper">
                      <div className="deco deco--white"></div>
                </div>
            </div>
            <ul className="home__border">
                <li className="home__border-line"></li>
                <li className="home__border-line"></li>
                <li className="home__border-line"></li>
                <li className="home__border-line"></li>
            </ul>
            <div className={`home__share-list home__share-list--desktop home__share-list--${isOpen ? 'open' : 'close'}`}>
                <a onClick={onClickShareButton} className="home__share-list__open-close btn btn--share"></a>
                <ul className="home__share-list__container">
                    <li className="home__share-list__item"><a data-trackable="1" data-share-network="facebook" className="btn btn--facebook"/></li>
                    <li className="home__share-list__item"><a data-trackable="1" data-share-network="whatsapp" className="btn btn--whatsapp"/></li>
                    <li className="home__share-list__item"><a data-trackable="1" data-share-network="messenger" className="btn btn--messenger"/></li>
                </ul>
            </div>
            <div className="home__share-list home__share-list--mobile">
                <h2>SHARE</h2>
                <ul className="home__share-list__container">
                    <li className="home__share-list__item"><a data-trackable="1" data-share-network="facebook" className="btn btn--transparent btn--inv btn--facebook"/></li>
                    <li className="home__share-list__item"><a data-trackable="1" data-share-network="whatsapp" className="btn btn--transparent btn--inv btn--whatsapp"/></li>
                    <li className="home__share-list__item"><a data-trackable="1" data-share-network="messenger" className="btn btn--transparent btn--inv btn--messenger"/></li>
                </ul>
                <hr style={{margin:'1em 0'}}/>
                <Link to="/mentions_legales">Mentions Légales</Link>
            </div>
        </div>

    return out;

}

export default Home;