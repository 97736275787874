import React from 'react'
import Tile from './Tile'

const TileList = ({theme,posts}) =>
{

    const cta = theme != "N"
              ? 'https://www.sephora.fr/p/lip-glowy-balm---baume-brillant-pour-les-levres-P4041129.html?utm_source=paristonic&utm_medium=media&utm_campaign=coad_laneige_lipglowingbalm_paid_com_site_pagelipglowingbalm'
              : 'https://www.sephora.fr/p/lip-sleeping-mask---masque-de-nuit-pour-les-levres-P3662005.html?utm_source=paristonic&utm_medium=media&utm_campaign=coad_laneige_lipglowingbalm_paid_com_site_pagelipsleepingmask';

    const xs  = posts.filter((x) => theme === '' || x.theme === theme) as any[];
    const out =
        <div className="tile-list">
            <ul className="tile-list__container">
                <li className="tile-list__item tile-list__item--cta">
                    <a data-trackable="1" target="_blank" rel="noopener" href={cta} className={`home__cta home__cta--${theme}`}>
                        <div className="home__cta-inner">
                            <div className="home__cta__text">
                                <div className="home__cta__text-inner">
                                    <p className="home__cta__text-title">RETROUVEZ LES PRODUITS<i/>LANEIGE</p>
                                    <span className="home__cta__text-img"/>
                                </div>
                            </div>
                            <div className="home__cta__img">
                                <div className="home__cta__img-inner">
                                    
                                </div>
                            </div>
                            <div className="home__cta__deco-wrapper">
                                <div style={{marginBottom:-1}} className={`deco deco--inv deco--small`}></div>
                            </div>
                            <div className="home__cta__cta-wrapper">
                                <div className="cta cta--small cta--inv">DÉCOUVRIR</div>
                            </div>
                        </div>
                    </a>
                </li>
                {xs.map((post,i) => 
                    <li className="tile-list__item">
                        <Tile {...post} />
                    </li>
                )}
            </ul>
        </div>

    return out
}   

export default TileList;
