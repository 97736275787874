import React from 'react'
import {Link} from 'gatsby'

const Tile = ({publicURL,img,title}) =>
{

    const out = 
            <Link to={publicURL} className="tile">
                <div className="tile__border">
                    <div className="tile__inner">
                        <picture className="tile__picture">
                            <img loading="lazy" src={img} alt={title} />
                        </picture>
                        <div className="tile__title-wrapper">
                            <h2 className="tile__title">{title}</h2>
                        </div>
                    </div>
                </div>
            </Link>

    return out

}

export default Tile;
